import {NextPageContext} from 'next'
import React, {useCallback, useEffect} from 'react'
import {fetchSSRData} from 'src/page-utils'
import {useRouter} from 'next/router'
import dynamic from 'next/dynamic'
import {
  useLoadingStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'

const LoginPage = dynamic(import('src/components/Login/LoginPage'))

const ScreenLoginRedirectionContainer = dynamic(
  import('src/components/Login/ScreenLoginRedirectionContainer')
)

function Login({pageData, query}: any) {
  const {config} = pageData ?? {}
  const {loginScreen, redirectionScreen} = config || {}
  const {url = ''} = query || {}

  const {
    user: {isLoggedIn},
  } = useUserStore()

  const {isUserHydration} = useLoadingStore()

  if (isUserHydration) {
    return null
  }

  return (
    <>
      {isLoggedIn ? (
        <ScreenLoginRedirectionContainer
          state={{redirectionScreen, redirectionLink: url}}
        />
      ) : (
        <LoginPage state={{loginScreen, redirectionLink: url}} />
      )}
    </>
  )
}

export async function getServerSideProps(context: NextPageContext) {
  return fetchSSRData(context, [
    {key: 'config', url: 'page/mwsc/static/login-page', isPrimary: true},
  ])
}

export default Login
